export const dropdownData = {
  methods: [
    { label: "GET", value: "GET", color: "success" },
    { label: "POST", value: "POST", color: "info" },
    { label: "PUT", value: "PUT", color: "warning" },
    { label: "DELETE", value: "DELETE", color: "danger" },
  ],
  contentTypes: [
    { label: "String", value: "string" },
    { label: "Number", value: "number" },
    { label: "Boolean", value: "boolean" },
    { label: "Object", value: "object" },
    { label: "List", value: "list" },
  ],
  statusCodes: [
    { label: "200", value: "200" },
    { label: "201", value: "201" },
    { label: "300", value: "300" },
    { label: "301", value: "301" },
    { label: "302", value: "302" },
    { label: "303", value: "303" },
    { label: "400", value: "400" },
    { label: "401", value: "401" },
    { label: "403", value: "403" },
    { label: "404", value: "404" },
    { label: "500", value: "500" },
    { label: "501", value: "501" },
    { label: "503", value: "503" },
    { label: "504", value: "504" },
  ],
};
