import { IModelEntity } from "@entities/Model";

export const sample: IModelEntity[] = [
  {
    id: "1",
    name: "IT Management System",
    variables: [
      {
        id: "1",
        name: "employeeId",
        type: "string",
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "2",
        name: "employeeName",
        type: "string",
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "3",
        name: "department",
        type: "string",
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "4",
        name: "isFullTime",
        type: "boolean",
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "5",
        name: "project",
        type: {
          id: "101",
          name: "ProjectDetails",
          variables: [
            {
              id: "1",
              name: "projectId",
              type: "string",
              isNullable: false,
            },
            {
              id: "2",
              name: "projectName",
              type: "string",
              isNullable: false,
            },
            {
              id: "3",
              name: "startDate",
              type: "string",
              isNullable: false,
            },
            {
              id: "4",
              name: "endDate",
              type: "string",
              isNullable: true,
            },
            {
              id: "5",
              name: "client",
              type: {
                id: "201",
                name: "ClientDetails",
                variables: [
                  {
                    id: "1",
                    name: "clientId",
                    type: "string",
                    isNullable: false,
                  },
                  {
                    id: "2",
                    name: "clientName",
                    type: "string",
                    isNullable: false,
                  },
                  {
                    id: "3",
                    name: "contactInfo",
                    type: {
                      id: "301",
                      name: "ContactInfo",
                      variables: [
                        {
                          id: "1",
                          name: "email",
                          type: "string",
                          isNullable: false,
                        },
                        {
                          id: "2",
                          name: "phone",
                          type: "string",
                          isNullable: true,
                        },
                      ],
                    },
                    isNullable: false,
                  },
                ],
              },
              isNullable: false,
            },
          ],
        },
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
    ],
  },
  {
    id: "2",
    name: "Employee Management System",
    variables: [
      {
        id: "1",
        name: "employeeId",
        type: "string",
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "2",
        name: "employeeName",
        type: "string",
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "3",
        name: "role",
        type: "string",
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "4",
        name: "isActive",
        type: "boolean",
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
    ],
  },
  {
    id: "3",
    name: "Project Management System",
    variables: [
      {
        id: "1",
        name: "projectId",
        type: "string",
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "2",
        name: "projectName",
        type: "string",
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "3",
        name: "startDate",
        type: "string",
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "4",
        name: "endDate",
        type: "string",
        isNullable: true,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "5",
        name: "team",
        type: {
          id: "201",
          name: "TeamDetails",
          variables: [
            {
              id: "1",
              name: "memberId",
              type: "string",
              isNullable: false,
            },
            {
              id: "2",
              name: "memberName",
              type: "string",
              isNullable: false,
            },
            {
              id: "3",
              name: "role",
              type: "string",
              isNullable: false,
            },
          ],
        },
        isNullable: false,
      },
    ],
  },
  {
    id: "4",
    name: "Client Management System",
    variables: [
      {
        id: "1",
        name: "clientId",
        type: "string",
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "2",
        name: "clientName",
        type: "string",
        isNullable: false,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "3",
        name: "industry",
        type: "string",
        isNullable: true,
        createdAt: new Date().toISOString(),
        createdBy: "admin",
        updatedAt: new Date().toISOString(),
        updatedBy: "admin",
      },
      {
        id: "4",
        name: "contact",
        type: {
          id: "301",
          name: "ContactDetails",
          variables: [
            {
              id: "1",
              name: "email",
              type: "string",
              isNullable: false,
            },
            {
              id: "2",
              name: "phone",
              type: "string",
              isNullable: true,
            },
          ],
        },
        isNullable: false,
      },
    ],
  },
];
